<template>
  <div>
    <v-container fluid class="grey lighten-5">
      <v-row align="center">
        <v-col class="text-center elevation-1" cols="12">
          <h2>이벤트 승인 요청 이력 조회</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="grey lighten-5">
      <v-row dense class="grey lighten-5 ml-1 mr-1 gutters">
        <v-col class="xs" cols="12" sm="3">
          <v-text-field v-model="mSUB_NO" label="승인번호" required dense />
        </v-col>
        <v-col class="xs" cols="12" sm="3">
          <v-text-field v-model="mADMIN_NAME" label="이름" required dense />
        </v-col>

        <v-col class="xs" cols="12" sm="3">
          <v-text-field v-model="mCOMPANY_NAME" label="상호명" required dense />
        </v-col>
        <v-col class="xs" cols="12" sm="3">
          <v-select
            v-model="mOPTION_SERVICE"
            :items="items2"
            item-text="state"
            item-value="id"
            label="선택서비스"
            required
            dense
            @change="populateService(mOPTION_SERVICE)"
          />
        </v-col>
      </v-row>
      <v-row dense class="grey lighten-5 ml-2 mr-2 gutters">
        <v-col class="xs" cols="12" sm="3">
          <v-select
            v-model="mCPID"
            :items="items5"
            item-text="SCODE"
            item-value="SCODE_DESC"
            label="CAS ID"
            dense
            @change="populateService(mCPID)"
          ></v-select>
        </v-col>
        <v-col class="xs" cols="12" sm="3">
          <v-menu
            v-model="start_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="mSTART_REG_DATE"
                label="등록일자(시작)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                dense
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="mSTART_REG_DATE"
              @input="start_date_menu = false"
            />
          </v-menu>
        </v-col>
        <v-col class="xs" cols="12" sm="3">
          <v-menu
            v-model="end_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="mEND_REG_DATE"
                label="등록일자(종료)"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                dense
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="mEND_REG_DATE"
              @input="end_date_menu = false"
            />
          </v-menu>
        </v-col>
      </v-row>

      <v-row class="grey lighten-5 ml-2 mr-2" align="center">
        <v-col style="overflow: auto">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-col>
        <v-col class="xs justify-end align" cols="12" sm="6">
          <v-btn
            class="mr-4"
            color="success"
            elevation="4"
            @click="makeExcelFile5"
          >
            EXFile
          </v-btn>
          <v-btn class="mr-4" color="success" elevation="4" @click="onReset">
            초기화
          </v-btn>
          <v-btn class="mr-4" color="success" elevation="4" @click="onSubmit">
            조회
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="grey lighten-5">
      <v-row class="grey lighten-5">
        <v-col style="overflow: auto">
          <v-data-table
            :headers="headers4"
            :items="desserts"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :items-per-page="10"
            item-key="SEQ"
            show-expand
            class="elevation-1 grey lighten-5"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.CPID }}</td>
                <td>{{ props.item.SUB_NO }}</td>
                <td>{{ props.item.ADMIN_NAME }}</td>
                <td>{{ props.item.TEL_NUMBER }}</td>
                <td>{{ props.item.COMPANY_NAME }}</td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                CASID : {{ item.CPID }}, 승인번호 : {{ item.SUB_NO }}, 이름 :
                {{ item.ADMIN_NAME }}, 상호명 : {{ item.COMPANY_NAME }},
                전화번호 : {{ item.TEL_NUMBER }}, 주소1 : {{ item.ADDRESS1 }},
                주소2 : {{ item.ADDRESS2 }}, 기본서비스 : {{ item.SERVICE1 }},
                선택서비스 : {{ item.SERVICE2 }}, 기타 : {{ item.SERVICE3 }},
                개인정보 수집 동의 : {{ item.PRIVACY_COLLECTION }}, 개인정보
                제공 동의 : {{ item.PRIVACY_THIRDPARTY }}, 수신코드 :
                {{ item.RESPCODE }}, 수신메세지 : {{ item.RESPMSG }}, 상태코드 :
                {{ item.STATUS }}, 등록일자 : {{ item.REG_DATE }}, 등록시간 :
                {{ item.REG_TIME }}, 변경일자 : {{ item.CHG_DATE }}, 변경시간:
                {{ item.CHG_TIME }}
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"; // mapGetters를 추가한다
import Xlsx from "xlsx"; // mapGetters를 추가한다

const userStore = "userStore";
const code = "code";

export default {
  name: "TestPage",
  data() {
    return {
      allItems: "",
      expanded: [],
      singleExpand: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      start_date_menu: false,
      end_date_menu: false,
      selected0: [],
      search: "",
      desserts: [],
      mCPID: "",
      mCPIDDESC: "",
      mSUB_NO: "",
      mADMIN_NAME: "",
      mCOMPANY_NAME: "",
      mSTART_REG_DATE: "",
      mEND_REG_DATE: "",
      mOPTION_SERVICE: "",
      headers: [
        {
          text: "CASID",
          value: "CPID",
          sortable: false,
          width: "100px",
          fixed: true,
        },
        { text: "승인번호", value: "SUB_NO", width: "100px", fixed: true },
        { text: "이름", value: "ADMIN_NAME", width: "100px" },
        { text: "전화번호", value: "TEL_NUMBER", width: "150px" },
        { text: "상호명", value: "COMPANY_NAME", width: "300px" },
      ],
      AppDataExcel: [],
    };
  },
  computed: {
    headers0() {
      return this.headers.map((header) =>
        Object.assign({}, header, { fixed: false })
      );
    },
    headers2() {
      return [
        { text: "", fixed: true, width: "100px", sortable: false },
      ].concat(this.headers);
    },
    headers3() {
      return [
        { text: "", value: "data-table-expand", fixed: true, width: "10px" },
      ].concat(this.headers);
    },
    headers4() {
      return this.headers.concat([
        { text: "", value: "data-table-expand", fixed: true, width: "10px" },
      ]);
    },
    headers5() {
      return this.headers3;
    },
    items2() {
      var selectAll = [{ state: "전체선택", id: null }];
      return selectAll.concat(this.$store.getters["userStore/GE_SERVICE2"]);
    },
    items4() {
      const allItems1 = this.items3;
      var selectAllGames = [{ state: "전체선택", id: null }];
      console.log("getters 결과", allItems1);
      return selectAllGames.concat(allItems1);
    },
    items5() {
      var selectAll = [{ SCODE: "전체선택", SCODE_DESC: null }];
      //return selectAll.concat(this.$store.getters["code/GE_CPID_CODE"]);
      return selectAll.concat(this.gCPID_CODE);
    },
    ...mapGetters("userStore", {
      items3: "GE_SERVICE2",
    }),
    ...mapGetters(code, {
      gCPID_CODE: "GE_CPID_CODE",
    }),
  },
  mounted() {
    this.oninitcpidCode();
    console.log("Parent mounted");
  },
  methods: {
    ...mapActions(code, ["CPID_CODE"]),
    ...mapActions(userStore, ["GET_DATA"]),
    async oninitcpidCode() {
      try {
        let Result = await this.oncpidcode();
        console.log("oninitZipCode 결과", Result);
      } catch (err) {
        console.error(err);
      }
    },
    async oncpidcode() {
      try {
        const Result = await this.CPID_CODE({
          fun_code: "scode",
          MCODE: "CPID",
          LCODE: "00000",
        });
        if (Result) {
          //this.mCPID = Result.data[0].SCODE;
          //this.mCPIDDESC = Result.data[0].SCODE_DESC;
          console.log("oncpidcode 결과", Result);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onSubmit() {
      try {
        const getResult = await this.GET_DATA({
          fun_code: "tselect",
          cas_id: this.mCPID,
          sub_no: this.mSUB_NO,
          admin_name: this.mADMIN_NAME,
          company_name: this.mCOMPANY_NAME,
          start_reg_date: this.mSTART_REG_DATE,
          end_reg_date: this.mEND_REG_DATE,
          service2: this.mOPTION_SERVICE,
        });
        if (getResult.data.repCode == "00") {
          console.log("로그인 결과", getResult);
          this.desserts = getResult.data.repData;
        } else {
          console.log("로그인 99 결과", getResult);
          this.desserts = getResult.data.repData;
        }
      } catch (err) {
        console.error(err);
      }
    },
    onReset() {
      this.mSUB_NO = "x";
      this.onSubmit();
      this.mCPID = "";
      this.mSUB_NO = "";
      this.mADMIN_NAME = "";
      this.mCOMPANY_NAME = "";
      this.mSTART_REG_DATE = "";
      this.mEND_REG_DATE = "";
      this.mOPTION_SERVICE = "";
    },
    makeExcelFile5() {
      const currDate = new Date();
      const desiredDate =
        currDate.getFullYear() +
        "-" +
        (currDate.getMonth() + 1) +
        "-" +
        currDate.getDate();
      const time =
        currDate.getHours() +
        ":" +
        currDate.getMinutes() +
        ":" +
        currDate.getSeconds();
      this.excelMakeData();
      const fileName =
        "토마토세븐 해충방제 무료체험 신청자명단_" +
        desiredDate +
        " " +
        time +
        ".xlsx";
      const workBook = Xlsx.utils.book_new();
      const workSheet = Xlsx.utils.json_to_sheet(this.AppDataExcel);
      Xlsx.utils.book_append_sheet(workBook, workSheet, "신청자명단");
      Xlsx.writeFile(workBook, fileName);
    },
    populateService(key) {
      console.log(key);
    },
    excelMakeData() {
      const AppArray = [];
      this.desserts.forEach(function (value) {
        const AppData = {};
        AppData.순서 = value.SEQ;
        AppData.CASID = value.CPID;
        // AppData.승인번호 = value.SUB_NO;
        AppData.주소 = value.ADDRESS1 + " " + value.ADDRESS2;
        AppData.이름 = value.ADMIN_NAME;
        AppData.상호명 = value.COMPANY_NAME;
        AppData.전화번호 = value.TEL_NUMBER;
        AppData.주소1 = value.ADDRESS1;
        AppData.주소2 = value.ADDRESS2;
        AppData.기본서비스 = value.SERVICE1;
        AppData.선택서비스 = value.SERVICE2;
        AppData.여분 = value.SERVICE3;
        AppData.개인수집동의 = value.PRIVACY_COLLECTION;
        AppData.정보제공동의 = value.PRIVACY_THIRDPARTY;
        AppData.수신코드 = value.RESPCODE;
        AppData.수신메세지 = value.RESPMSG;
        AppData.상태코드 = value.STATUS;
        AppData.등록일자 = value.REG_DATE;
        AppData.등록시간 = value.REG_TIME;
        AppData.변경일자 = value.CHG_DATE;
        AppData.변경시간 = value.CHG_TIME;
        AppData.상담여부 = value.ADV_YN;

        // console.log(AppData)
        AppArray.push(AppData);
      });
      this.AppDataExcel = AppArray;
    },
  },
};
</script>

<style scoped>
v-text-field {
  background-color: #6ec0e9;
}
</style>
