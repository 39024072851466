<template>
  <div id="Worklog">
    <work-log-page />
  </div>
</template>

<script>
import WorkLogPage from "../components/WorkLogPage";

export default {
  name: "Worklog",

  components: {
    WorkLogPage,
  },
};
</script>
